import React, { useState } from "react";
import "../styles.css";
function Maps() {

  return (
    <>
    <div class="section-maps">
      <div class="container mt-5">
        <div class="row">

          <div class="col-md-4">
            <h2>Find Us</h2>
            <p>126 Thorntree Road, Thornaby, <br />TS17 8LX</p>

            <a href="tel:+441642 679555" class="phone-link">01642 679 555</a>

            <br />
            <br />
            <br />

            <h2>Hours</h2>
              <p>Mon-Sat: 11:30am-9:00pm<br />
              Sun: Closed 
            </p>
          </div>
          
          <div class="col-md-8">
            <iframe
            class="map"
            title="Marina Munch Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2314.3477587671127!2d-1.3006440231942695!3d54.54499487266109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e938939963569%3A0xa81fe663167c929b!2sMarina%20Munch!5e0!3m2!1sen!2suk!4v1701718693862!5m2!1sen!2suk"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Maps;