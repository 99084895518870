import React from "react";
import hero1 from "../Images/hero-1.png";
import "../styles.css";

function Carousel() {
  return (
    <>
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
      >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src={hero1} class="cImg d-block w-100" alt="Marina Munch Fish" />

          <div class="carousel-caption d-md-flex justify-content-center align-items-center">
            <div class="text-center">
              <h1 class="hero-heading">Taste On</h1>
              <h1 class="hero-heading">The Go</h1>

              <a href="/takeaway" class="hero-link" alt="Link to Order Food">
                <button type="button" class="hero-button">Order Now</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Carousel;
