import React from 'react';
import { TouchableOpacity, View, StyleSheet, Platform, Image, Text, useWindowDimensions } from 'react-native';

import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';

// Import screens
import AccountScreen from '../screens/AccountScreen';
import ProfileScreen from '../screens/ProfileScreen'
import AddressesScreen from '../screens/AddressesScreen'
import TermsScreen from '../screens/TermsScreen';
import PrivacyScreen from '../screens/PrivacyScreen';


import HamburgerIcon from './../containers/HambugerIcon';
import HeaderWeb from './../containers/HeaderWeb'

import { colors } from '../constants/colors'

const Account = createStackNavigator();

function Header({ openDrawer }) {

  const window = useWindowDimensions();

  return (
    <View style={styles.main}>
      <View style={[styles.container, {paddingHorizontal: window.width > 768 ? 30 : 0} ]}>
        <TouchableOpacity onPress={() => openDrawer()}>
         <HamburgerIcon />
       </TouchableOpacity>

       {Platform.OS == "web" ? <HeaderWeb /> : null}
      </View>
    </View>
  )
}

export const AccountStack = (props) => (
      <Account.Navigator
        drawerPosition="right"
        drawerType="slide"
        initialRouteName="Account"
        screenOptions={{
          headerTintColor: colors.white,
          headerStyle: {
          elevation: 0,
          shadowOpacity: 0,
          borderBottomWidth: 1,
          borderBottomColor: colors.primary,
          backgroundColor: colors.primary,
          height: Platform.OS == "web" ? 90 : 100,
        } }} >
        <Account.Screen
         name="Account"
         component={AccountScreen}
         options={{
           title: "Account \u00BB Marina Munch",
           headerTitle: () => <Header openDrawer={() => props.navigation.openDrawer()} />,
           headerTitleAlign: "left"
         }}
        />
        <Account.Screen
         name="Profile"
         component={ProfileScreen}
         options={{
           headerTitle: () => <Header openDrawer={() => props.navigation.openDrawer()} />,
         }}
        />
        <Account.Screen
         name="Addresses"
         component={AddressesScreen}
         options={{
           title: "Addresses \u00BB Marina Munch",
           headerTitle: () => <Header openDrawer={() => props.navigation.openDrawer()} />,
         }}
        />
        <Account.Screen
         name="Terms"
         component={TermsScreen}
         options={{
           title: "Terms & Conditions \u00BB Marina Munch",
           headerTitle: () => <Header openDrawer={() => props.navigation.openDrawer()} />
         }}
        >
        </Account.Screen>
        <Account.Screen
         name="Privacy"
         component={PrivacyScreen}
         options={{
           title: "Privacy Policy \u00BB Marina Munch",
           headerTitle: () => <Header openDrawer={() => props.navigation.openDrawer()} />
         }}
        >
        </Account.Screen>
      </Account.Navigator>
);

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
  container: {
    width: "100%",
    flexDirection: "row",
    alignSelf: "center",
    maxWidth: 1280,
  },
  text: {
    color: colors.text,
    padding: 20,
  },
  branch: {
    paddingTop: 20,
    paddingHorizontal: 30,
    backgroundColor: colors.primary
  },
  categories: {
    paddingTop: 30,
    paddingBottom: 20,
    backgroundColor: colors.primary
  },
  items: {
    flex: 1,
  },
  itemsHeader: {
    flex: 1,
    fontSize: 24,
    fontWeight: "700",
    paddingTop: 45,
    paddingBottom: 35,
    paddingHorizontal: 30,
  }
});
