import React from 'react';
import "./styles.css";
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from "./Components/Carousel";
import Maps from "./Components/Maps";
import Footer from "./Components/Footer";

export default function Landing() {
  return (
    <div class="App">
      <Carousel />
      <Maps />
      <Footer />
    </div>
  );
}
